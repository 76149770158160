'use strict';

Gri.module({
  name: 'image-desc-wrapper',
  ieVersion: null,
  $el: $('.image-desc-wrapper'),
  container: '.image-desc-wrapper',
  fn: function () {

    // region variables
    console.log("dataHotspot: ", dataHotspot);
    const $this = this.$el;
    let dataList = dataHotspot;
    let dataInteractivity = $this.data('interactivity');
    // endregion

    // region run
    $this.hotspot({
      data: dataList,
      tag: 'img', //optional (default is img)
      interactivity: dataInteractivity, // options : click, none (default is hover)
      hotspotClass: 'image-desc-wrapper-hotspot'
    });

    $('.image-desc-wrapper-hotspot').append('<i class="icon-cross-2"></i>');

    $('.image-desc-wrapper-hotspot').each(function (index, item) {
      console.log(index);
      $(this).css({
        left: $(this).find('.Hotspot_x').html(),
        top: $(this).find('.Hotspot_y').html()
      });
    });
    // endregion

    // region events

    // endregion

    // region events functions

    // endregion

    // region functions

    // endregion

  }
});
